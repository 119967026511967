<template>
  <v-container fluid>
    <patient-filter
      :patients="patients"
      :panel="true"
      @filterChange="filterChange"
    ></patient-filter>
    <v-card class="pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="appointments"
        :search="search"
        :loading="loading"
        :loading-text="$t('loading')"
        class=""
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  single-line
                  hide-details
                  clearable
                  dense
                ></v-text-field>
              </v-col>

              <v-col>
                <v-select
                  v-model="filterStatus"
                  hide-details
                  clearable
                  dense
                  :items="status"
                  :label="$t('appointments.appointmentStatus')"
                  item-text="text"
                  item-value="value"
                  @change="
                    () => {
                      filterItem.status = filterStatus;
                      refreshTable();
                    }
                  "
                ></v-select>
              </v-col>
              <v-col cols="auto">
                <v-dialog v-model="dialog" persistent max-width="600px">
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="isInRole('20')"
                  >
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t("add") }}

                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card>
                      <v-toolbar color="primary darken-1" dark>
                        <v-card-title>
                          <span class="">{{
                            (editedIndex === -1 ? $t("add") : $t("edit")) +
                              " " +
                              $t("appointments.appointment")
                          }}</span>
                        </v-card-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete
                                v-model="editedItem.doctorGuid"
                                :rules="rules"
                                :items="doctors"
                                :label="$t('doctors.doctorName')"
                                required
                                item-text="doctorName"
                                item-value="guid"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-autocomplete
                                v-model="editedItem.patientGuid"
                                :rules="rules"
                                :items="patients"
                                :label="$t('patients.patientName')"
                                required
                                item-text="patientDisplayName"
                                item-value="guid"
                              ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-datetime-picker
                                :label="$t('appointments.appointmentDate')"
                                v-model="editedItem.appointmentDate"
                                dateFormat="yyyy/MM/dd"
                                timeFormat="hh:mm aa"
                                :okText="$t('ok')"
                                :clearText="$t('cancel')"
                              >
                                <template v-slot:dateIcon>
                                  <v-icon> mdi-calendar </v-icon>
                                </template>
                                <template v-slot:timeIcon>
                                  <v-icon> mdi-clock </v-icon>
                                </template>
                              </v-datetime-picker>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="editedItem.note"
                                :label="$t('notes')"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-btn
                          color="green darken-1"
                          class="white--text"
                          @click="save"
                          :disabled="!valid"
                          :min-width="100"
                        >
                          <v-icon>mdi-content-save-outline</v-icon>
                          {{ $t("save") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="close">
                          {{ $t("cancel") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-col>
            </v-row>
            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="isInRole('21')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("edit") }} </span>
            </v-tooltip>
            <v-tooltip top v-if="isInRole('22')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  color="red darken-2"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.appointmentStatus`]="{ item }">
          <v-chip
            :color="
              item.appointmentStatus == 0
                ? 'yellow darken-3'
                : item.appointmentStatus == 1
                ? 'light-blue darken-1'
                : item.appointmentStatus == 2
                ? 'red  darken-1'
                : 'green darken-1'
            "
            class="ma-2 white--text"
          >
            {{ getText(item.appointmentStatus) }}
          </v-chip>
        </template>

        <template v-slot:[`item.appointmentDate`]="{ item }">
          {{ item.appointmentDate | moment("YYYY/MM/DD hh:mm A") }}
        </template>
      </v-data-table>
      <v-divider />
      <v-row class="mt-2">
        <v-col cols="2"></v-col>

        <v-col align-self="end">
          <v-pagination
            class="mb-2 "
            v-model="page"
            :length="pageLength ? pageLength : 0"
          ></v-pagination>
        </v-col>
        <v-col align-self="center" cols="2">
          <v-select
            dense
            class="pa-1"
            solo
            :label="$t('itemPerPage')"
            :items="itemPerPageSelect"
            item-text="text"
            item-value="value"
            v-model="itemsPerPage"
            hide-details=""
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";

import PatientFilter from "../../components/PatientFilter.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
  components: { PatientFilter, ConfirmDialog },
  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      itemPerPageSelect: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: this.$t("all"), value: -1 },
      ],
      menu: null,
      valid: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      dialogStatus: false,
      editedIndex: -1,
      search: "",
      filterStatus: null,

      headers: [
        { text: this.$t("doctors.doctorName"), value: "doctorName" },
        { text: this.$t("patients.patientName"), value: "patientName" },
        {
          text: this.$t("appointments.appointmentDate"),
          value: "appointmentDate",
        },
        {
          text: this.$t("appointments.appointmentStatus"),
          value: "appointmentStatus",
        },
        { text: this.$t("notes"), value: "note" },
        { text: "", value: "actions" },
      ],
      status: [
        { value: 0, text: this.$t("patients.waitTab"), icon: "mdi-clock" },
        { value: 1, text: this.$t("patients.treatmentTab"), icon: "mdi-heart" },
        {
          value: 2,
          text: this.$t("patients.cancelTab"),
          icon: "mdi-close-thick",
        },
        { value: 3, text: this.$t("patients.doneTab"), icon: "mdi-check-bold" },
      ],
      appointments: [],
      doctors: [],
      patients: [],
      filterItem: {
        patientName: "",
        fromDate: this.dateNow,
        toDate: this.dateNow,
        status: null,
      },
      editedItem: {
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: new Date(),
        appointmentStatus: 0,
        note: "",
      },
      defaultItem: {
        //doctorGuid: "00000000-0000-0000-0000-000000000000",
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: new Date(),
        appointmentStatus: 0,
        note: "",
      },

      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    console.log(this.dateNow);
    this.loading = true;
    this.refreshTable();
  },
  computed: {
    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        var div = this.appointments.length / this.itemsPerPage;
        return Number.isInteger(div) ? div : (div | 0) + 1;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },

  methods: {
    getText(i) {
      var result = this.status.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },
    editItem(item) {
      this.editedIndex = this.appointments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.appointmentDate = new Date(
        this.editedItem.appointmentDate
      );

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.appointments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    changeItemStatus(item) {
      this.editedIndex = this.appointments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogStatus = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.appointments[this.editedIndex];

      axios
        .delete("Appointment/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    // statusItemConfirm() {
    //   var item = this.appointments[this.editedIndex];

    //   axios
    //     .get(
    //       "Appointment/ChangeStatus?guid=" +
    //         item.guid +
    //         "&status=" +
    //         this.editedItem.appointmentStatus
    //     )
    //     .then((response) => {
    //       if (response.data.status == "Successful") {
    //         this.$toast(this.$t("operationAccomplishedSuccessfully"));
    //       } else {
    //         this.$toast.error(this.$t("error." + response.data.message));
    //       }

    //       this.refreshTable();
    //     })
    //     .catch((e) => {
    //       this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
    //       console.log(e);
    //     });

    //   this.closeStatus();
    // },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeStatus() {
      this.dialogStatus = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    filterChange(filterItem) {
      this.filterItem = filterItem;
      this.filterItem.status = this.filterStatus;
      this.refreshTable();
    },

    refreshTable() {
      axios
        .get("Appointment/Get?filter=" + JSON.stringify(this.filterItem))
        .then((response) => {
          this.appointments = response.data.data;

          if (this.isInRole("6")) {
            axios.get("Doctor/GetForAppointment").then((response) => {
              this.doctors = response.data.data;
            });
          }

          axios.get("Patient").then((response) => {
            this.patients = response.data.data;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.appointments[this.editedIndex];

          updatedItem.doctorGuid = this.editedItem.doctorGuid;
          updatedItem.patientGuid = this.editedItem.patientGuid;
          updatedItem.appointmentDate = JSON.parse(
            JSON.stringify(this.editedItem.appointmentDate.toLocaleString())
          );
          updatedItem.appointmentStatus = this.editedItem.appointmentStatus;
          updatedItem.note = this.editedItem.note;

          axios
            .post("Appointment/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.appointments[this.editedIndex], this.editedItem);
        } else {
          var data = {
            doctorGuid: this.editedItem.doctorGuid,
            patientGuid: this.editedItem.patientGuid,
            appointmentDate: JSON.parse(
              JSON.stringify(this.editedItem.appointmentDate.toLocaleString())
            ),
            appointmentStatus: this.editedItem.appointmentStatus,
            note: this.editedItem.note,
          };

          axios
            .post("Appointment/Add", data)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style scoped></style>
